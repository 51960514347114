import { getTimeZones, timeZonesNames } from '@vvo/tzdb';
import { Maybe } from 'graphql/jsutils/Maybe';

const tizenNotSupportedTimezones = [
  'America/Noronha',
  'America/Nuuk',
  'America/Scoresbysund',
  'Atlantic/South_Georgia',
  'Australia/Eucla',
  'Australia/Lord_Howe',
  'Pacific/Chatham',
  'Pacific/Kiritimati',
  'Pacific/Marquesas',
];

/** does not contain Tizen unsupported timezones */
export const safeTimezoneNames = timeZonesNames.filter(
  (x) => !tizenNotSupportedTimezones.includes(x),
);
const timeZones = getTimeZones();

export const getCachedTimezones = () => timeZones;
export const getTimeZoneByName = (tzName: Maybe<string>) => {
  if (tzName) {
    return timeZones.find(
      (x) =>
        x.name === tzName ||
        x.alternativeName === tzName ||
        x.group.includes(tzName),
    );
  }
  return null;
};

export const getTimezoneOffsetByName = (tzName: Maybe<string>) => {
  if (!tzName) return '';
  try {
    return getTimeZoneByName(tzName)?.currentTimeFormat.split(' ')[0];
  } catch (e) {
    console.error('ERROR: getTimezoneOffsetByName', e);
    return '';
  }
};
